import { createContext } from "react";
import { FormContextState } from "@component/core/Context/Form/typeDefs";
import flatten from "@lib/form/flatten";
export const initState: FormContextState = {};
export const FormContext = createContext({
  form: initState,
  setForm: (diff: Partial<FormContextState>) => {},
  mergeForm: (diff: Partial<FormContextState>) => {},
  getByPath: (path: string): any => {},
  removeByPath: (path: string) => {},
  addByPath: (path: string, value: any) => {},
  updateByPath: (path: string, value: any) => {}
});
export default FormContext;