import FormContext from "@component/core/Context/Form/FormContext";
import { Text as FluentText, Checkbox } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import { useId, Input, Label, Radio, RadioGroup } from "@fluentui/react-components";
import { TextFieldRegular } from "@fluentui/react-icons";
import React from "react";
import { useContext, useEffect, useState } from "react";
const tag = "@component/view/Boolean";
export function Boolean({
  item,
  titleWidth,
  path = ""
}) {
  const {
    form,
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const inputId = useId("input");
  const p = `${path ? path + "." : ""}${item.attrib}`;
  const [value, setValue] = useState(getByPath(p));
  const onChange = (ev, data) => {
    setValue(data.value);
    //updateByPath(p, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            {item.display !== "radio-group" && <Checkbox checked={value} />}
            {item.display === "radio-group" && <RadioGroup layout="horizontal" value={String(value)}>
                    <Radio value="true" label={item.trueTitle} />
                    <Radio value="false" label={item.falseTitle} />
                </RadioGroup>}
        </div>;
}
export default Boolean;