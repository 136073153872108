import Section from "@component/view/Form/Section";
import Text from "@component/view/Form/Text";
import Number from "@component/view/Form/Number";
import TextArea from "@component/view/Form/TextArea";
import Date from "@component/view/Form/Date";
import Contact from "@component/view/Form/Contact";
import ForOf from "@component/view/Form/ForOf";
import Enum from "@component/view/Form/Enum";
import Boolean from "@component/view/Form/Boolean";
const tag = "@component/view/Form/Item";
export function Item(props) {
  switch ((props.item ? props.item.type || "" : "").toLowerCase()) {
    case "section":
      return <Section {...props} />;
    case "text":
      return <Text {...props} />;
    case "number":
      return <Number {...props} />;
    case "textarea":
      return <TextArea {...props} />;
    case "date":
      return <Date {...props} />;
    case "contact":
      return <Contact {...props} />;
    case "enum":
      return <Enum {...props} />;
    case "for-of":
      return <ForOf {...props} />;
    case "boolean":
      return <Boolean {...props} />;
    default:
      return null;
  }
}
export default Item;