import ContextProvider from "@core/Context/ContextProvider";
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import Router from "@core/Router";
export function App() {
  return <FluentProvider theme={teamsLightTheme}>
            <ContextProvider>
                <Router />
            </ContextProvider>
        </FluentProvider>;
}
export default App;