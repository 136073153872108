import { Text } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import Item from "@component/view/Form/Item";
import { webLightTheme, tokens } from '@fluentui/react-components';
import { v4 as uuidv4 } from 'uuid';
const tag = "@component/view/Section";
export function Section({
  item,
  path = "",
  titleWidth = null
}) {
  const section = item;
  return <div style={{
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch"
  }}>
            <Subtitle1 style={{
      marginBottom: 10,
      color: tokens.colorPaletteBlueForeground2
    }}>{item.title}</Subtitle1>
            {(item.items || []).map(item => {
      return <Item key={item.title || `${path ? path + "." : ""}${item.attrib}` || uuidv4()} item={item} titleWidth={typeof section.itemTitleWidth !== "undefined" ? section.itemTitleWidth : titleWidth} path={path} />;
    })}
        </div>;
}
export default Section;