import log from "@lib/log";
import { createRoot } from "react-dom/client";
import App from "@core/App";
import "./styles.scss";
import flatten from "@lib/form/flatten";
import unflatten from "@lib/form/unflatten";
import normalize from "@lib/form/normalize";
const tag = "@entry/index";
if (process.env.NODE_ENV === 'development') {
  if (typeof localStorage.debug === "undefined") {
    log.set.all();
  }
}
const run = async () => {
  const root = createRoot(document.getElementById("appRoot"));
  root.render(<App />);
  if (process.env.NODE_ENV === 'development') {
    window["web"] = {
      LOG,
      DEBUG,
      log,
      root,
      lib: {
        form: {
          flatten,
          unflatten,
          normalize
        }
      },
      context: {}
    };
  }
};
run();