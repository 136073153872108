import logger from "debug";
import ns from "@lib/log/ns";
export const debug = (tag: string, formatter: any, ...args: Array<any>) => {
  if (process.env.NODE_ENV === "development") {
    if (!ns[tag]) {
      ns[tag] = logger(tag);
    }
    ns[tag](formatter, ...args);
  }
};
globalThis.DEBUG = debug;
export default debug;