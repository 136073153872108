import { v4 as uuidv4 } from 'uuid';
export const flatten = (data, path = "") => {
  if (typeof data !== "object") {
    return {
      [path]: data
    };
  }
  const uids = [];
  const genUniqueUID = () => {
    let uid = uuidv4();
    if (uids.indexOf(uid) >= 0) {
      uid = uuidv4();
    }
    uids.push(uid);
    return uid;
  };
  let buff = data instanceof Array ? [{
    path: "",
    value: data
  }] : Object.keys(data).map(key => ({
    path: key,
    value: data[key]
  }));
  const obj = {};
  while (buff.length > 0) {
    const buffItem = buff.shift();
    if (typeof buffItem.value !== "object") {
      obj[`${path ? path + "." : ""}${buffItem.path}`] = buffItem.value;
    } else if (buffItem.value instanceof Array) {
      buff = buffItem.value.map((value, index, arr) => ({
        path: `${buffItem.path ? buffItem.path + "." : ""}${genUniqueUID()}`,
        value
      })).concat(buff);
    } else {
      buff = Object.keys(buffItem.value).map(key => ({
        path: `${buffItem.path}.${key}`,
        value: buffItem.value[key]
      })).concat(buff);
    }
  }
  return obj;
};
export default flatten;