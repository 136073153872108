import { createContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { NavigationContextState, NavigationContextStateHistoryItem, NavigationContextStateHistoryItemKey } from "@component/core/Context/Navigation/typeDefs";
export const initState: NavigationContextState = {
  history: [{
    active: true,
    key: NavigationContextStateHistoryItemKey.FORM,
    uuid: uuidv4(),
    data: {}
  }]
};
export const NavigationContext = createContext({
  navigation: initState,
  setNavigation: (diff: Partial<NavigationContextState>) => {},
  pushNavigation: (item: Partial<NavigationContextStateHistoryItem>) => {},
  backNavigation: () => {},
  clearNavigation: () => {}
});
export default NavigationContext;