import FormContext from "@component/core/Context/Form/FormContext";
import { Text as FluentText } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import { useId, Input, Label } from "@fluentui/react-components";
import { DatePicker, defaultDatePickerStrings } from "@fluentui/react-datepicker-compat";
import type { CalendarStrings } from "@fluentui/react-datepicker-compat";
import moment from "moment-timezone";
import { useContext, useState } from "react";
const tag = "@component/view/Text";
const localizedStrings: CalendarStrings = {
  ...defaultDatePickerStrings,
  days: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
  shortDays: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
  months: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
  shortMonths: ["Led", "Úno", "Bře", "Dub", "Kvě", "Čvn", "Čvc", "Srp", "Zář", "Říj", "Lis", "Pro"],
  goToToday: "Přejít na dnešek"
};
const formatDate = (dt: Date) => {
  return moment(dt).format("D. M. YYYY");
};
export function Text({
  item,
  titleWidth,
  path = ""
}) {
  const {
    form,
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const p = `${path ? path + "." : ""}${item.attrib}`;
  const [value, setValue] = useState(getByPath(p));
  const onChange = dt => {
    setValue(dt);
    //updateByPath(p, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <DatePicker strings={localizedStrings} firstDayOfWeek={1} style={{
      flex: "1 1 auto"
    }} formatDate={formatDate} value={moment(value).toDate()} onSelectDate={onChange} />

        </div>;
}
export default Text;