import { createContext } from "react";
import { FormDefinitionContextState } from "@component/core/Context/FormDefinition/typeDefs";
export const initState: FormDefinitionContextState = {
  _loaded: false,
  _error: false
};
export const FormDefinitionContext = createContext({
  formDefinition: initState,
  setFormDefinition: (diff: Partial<FormDefinitionContextState>) => {},
  mergeFormDefinition: (diff: Partial<FormDefinitionContextState>) => {}
});
export default FormDefinitionContext;