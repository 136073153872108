export enum NavigationContextStateHistoryItemKey {
  FORM = "FORM",
}
export interface NavigationContextStateHistoryItem {
  active: boolean;
  key: NavigationContextStateHistoryItemKey;
  uuid: string;
  data?: any;
}
export interface NavigationContextState {
  history: Array<NavigationContextStateHistoryItem>;
}