import { Text } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import Item from "@component/view/Form/Item";
import { webLightTheme, tokens } from '@fluentui/react-components';
import React, { useContext } from "react";
import unflatten from "@lib/form/unflatten";
import FormContext from "@component/core/Context/Form/FormContext";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import { Button } from "@fluentui/react-components";
import { AddRegular, DeleteRegular } from "@fluentui/react-icons";
const tag = "@component/view/ForOf";
import { flatten } from "@lib/form/flatten";
import merge from "@lib/merge";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';
import FormDefinitionContext from "@component/core/Context/FormDefinition/FormDefinitionContext";
const joinPaths = (path1, path2) => {
  return `${path1 ? path1 + "." : ""}${path2}`;
};
export const add = (data, defs, path) => {};
export function ForOf({
  item,
  path = "",
  titleWidth
}) {
  const {
    navigation
  } = useContext(NavigationContext);
  const ctx = navigation.history.filter(hi => !!hi.active)[0].data;
  const {
    form,
    setForm,
    mergeForm,
    removeByPath
  } = useContext(FormContext);
  const {
    formDefinition
  } = useContext(FormDefinitionContext);
  const section = item;
  const p = `${path ? path + "." : ""}${item.attrib}`;
  const arr = unflatten(form, p) || [];

  // TODO IMPROVE
  const addItem = () => {
    /*
    const uids = [];
    const genUniqueUID = () => {
        let uid = uuidv4();
        if (uids.indexOf(uid) >= 0) {
            uid = uuidv4();
        }
        uids.push(uid);
        return uid;
    }
          let d = {};
      let def = formDefinition[form.typ];
    let buff = def.sections.map(item => ({item, path: ""}));
    while (buff.length > 0) {
        const buffItem = buff.shift();
        if (buffItem.item.type === "section") {
            buff = buffItem.item.items.map(item => ({item, required: !!buffItem.required, path: buffItem.path})).concat(buff);
        } else if (buffItem.item.type === "for-of") {
            let _p = joinPaths(buffItem.path, buffItem.item.attrib) + ".";
            const arrKeys = Object.keys(form).filter(key => key.startsWith(p));
            if (arrKeys.length === 0) {
                buff = buffItem.item.item.map(i => ({item: i, path: `${_p}${genUniqueUID()}`, required: true})).concat(buff);
            }
        } else if (buffItem.required && buffItem.path.startsWidth(p)) {
            if (buffItem.item.type === "text") {
                d[`${buffItem.path}.${buffItem.item.attrib}`] = "";
            } else if (buffItem.item.type === "number") {
                d[`${buffItem.path}.${buffItem.item.attrib}`] = 0;
            } if (buffItem.item.type === "date") {
                d[`${buffItem.path}.${buffItem.item.attrib}`] = moment().toISOString();
            } else if (buffItem.item.type === "boolean") {
                d[`${buffItem.path}.${buffItem.item.attrib}`] = false;
            }
        }
    }
      mergeForm(d);
    */
  };
  return <React.Fragment>
            <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "stretch",
      marginBottom: 20
    }}>
                {(arr || []).map(i => {
        const deleteItem = () => {
          removeByPath(i._path);
        };
        return <div key={i._path} style={{
          paddingTop: 20,
          paddingBottom: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "stretch",
          borderBottom: "1px dotted #AAAAAA"
        }}>
                            <div style={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            marginRight: 10
          }}>
                                {item.item.map((itemdef, index) => <Item key={i._path + ";" + index} item={itemdef} path={i._path} titleWidth={titleWidth} />)}
                            </div>
                            {!ctx.readOnly && item.remove && <Button style={{
            alignSelf: "stretch"
          }} icon={<DeleteRegular />} disabled={arr.length < 2 && !!item.atLeastOne} onClick={deleteItem}>
                                </Button>}
                        </div>;
      })}
                {!ctx.readOnly && item.add && <Button style={{
        alignSelf: "center",
        marginTop: 10
      }} icon={<AddRegular />} onClick={addItem}>
                    </Button>}
            </div>
        </React.Fragment>;
}
export default ForOf;