import NavigationContextProvider from "@component/core/Context/Navigation/NavigationContextProvider";
import FormContextProvider from "@component/core/Context/Form/FormContextProvider";
import FormDefinitionContextProvider from "@component/core/Context/FormDefinition/FormDefinitionContextProvider";
export function ContextProvider({
  children
}) {
  return <NavigationContextProvider>
            <FormDefinitionContextProvider>
                <FormContextProvider>
                    {children}
                </FormContextProvider>
            </FormDefinitionContextProvider>
        </NavigationContextProvider>;
}
export default ContextProvider;