import { NavigationContextStateHistoryItem } from "@core/Context/Navigation/typeDefs";
import React from "react";
import styles from "@common/View/styles.scss";
export function View({
  navItem,
  children,
  style = {}
}) {
  const className = navItem.active ? styles.active : styles.inactive;
  return <div className={className} style={{
    ...style
  }}>
            {children}
        </div>;
}
export default View;