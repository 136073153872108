import FormContext from "@component/core/Context/Form/FormContext";
import { Text as FluentText } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import { useId, Input, Label } from "@fluentui/react-components";
import { TextFieldRegular } from "@fluentui/react-icons";
import React from "react";
import { useContext, useEffect, useState } from "react";
const tag = "@component/view/Text";
export function Text({
  item,
  titleWidth,
  path = ""
}) {
  const {
    form,
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const inputId = useId("input");
  const p = `${path ? path + "." : ""}${item.attrib}`;
  const pTitle = `${path ? path + "." : ""}${item.titleAttrib}`;
  const [value, setValue] = useState(getByPath(p));
  const [valueTitle, setValueTitle] = useState(item.titleAttrib ? getByPath(pTitle) : "");
  const onChange = (ev, data) => {
    setValue(data.value);
    //updateByPath(p, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            {!!item.titleAttrib && <Input style={{
      width: typeof titleWidth === "number" ? titleWidth - 10 : 240,
      marginRight: 10,
      flexShrink: 1
    }} contentAfter={<TextFieldRegular />} value={valueTitle} disabled={!!item.readOnly || !item.editTitle}
    //onChange={onChangeTitle}
    />}
            <Input id={inputId} style={{
      flex: "1 1 auto"
    }} contentAfter={<TextFieldRegular />} value={value} disabled={!!item.readOnly} onChange={onChange} />
        </div>;
}
export default Text;