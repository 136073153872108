import FormContext from "@component/core/Context/Form/FormContext";
import { Text as FluentText } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import { useId, Input, Label } from "@fluentui/react-components";
import { TextFieldRegular } from "@fluentui/react-icons";
import { useContext, useState } from "react";
const tag = "@component/view/Number";
export function Number({
  item,
  titleWidth,
  path = ""
}) {
  const {
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const inputId = useId("input");
  const p = `${path ? path + "." : ""}${item.attrib}`;
  const [value, setValue] = useState(getByPath(p));
  const onChange = (ev, data) => {
    setValue(data.value);
    //updateByPath(p, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <Input id={inputId} style={{
      flex: "1 1 auto"
    }} type="number" contentAfter={<TextFieldRegular />} disabled={!!item.readOnly} value={value} onChange={onChange} />

        </div>;
}
export default Number;