import axios from "axios";
import { FormDefinitionContext, initState } from "@component/core/Context/FormDefinition/FormDefinitionContext";
import { FormDefinitionContextState } from "@component/core/Context/FormDefinition/typeDefs";
import { useEffect, useRef, useState } from "react";
import merge from "@lib/merge";
const tag = "@component/core/Context/FormDefinition/FormDefinitionContextProvider";
const getDefinitionNames = async (): Promise<Array<string>> => {
  try {
    const aopts = {
      method: "get",
      url: "form/definition/definitions.json",
      headers: {
        "accept": "application/json"
      }
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data && res.data.definitions && res.data.definitions instanceof Array) {
      return res.data.definitions.filter(def => typeof def === "string" && def);
    } else {
      LOG(`${tag}:getDefinitionNames`, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(`${tag}:getDefinitionNames`, "error detected %O", e);
    throw e;
  }
};
const getDefinition = async (name: string): Promise<void> => {
  try {
    const aopts = {
      method: "get",
      url: `form/definition/${name}.json`,
      headers: {
        "accept": "application/json"
      }
    };
    let res = await axios(aopts);
    if (res.status === 200 && res.data) {
      return res.data;
    } else {
      LOG(`${tag}:getDefinition`, "unknown error detected, res: %O", res);
      return Promise.reject(res);
    }
  } catch (e) {
    LOG(`${tag}:getDefinition`, "error detected %O", e);
    throw e;
  }
};
const load = async (setStateRef): Promise<void> => {
  const diff: Partial<FormDefinitionContextState> = {};
  try {
    const definitionNames: Array<string> = await getDefinitionNames();
    for (const definitionName of definitionNames) {
      const definition = await getDefinition(definitionName);
      diff[definitionName] = definition;
    }
    diff._loaded = true;
  } catch (e) {
    diff._error = true;
    LOG(`${tag}:load`, "error detected: %O", e);
  }
  setStateRef.current(diff);
};
export function FormDefinitionContextProvider({
  children
}) {
  const [state, setState] = useState(initState);
  const stateRef = useRef(state);
  const setStateRef = useRef(setState);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  useEffect(() => {
    setStateRef.current = setState;
  }, [setState]);
  useEffect(() => {
    load(setStateRef);
  }, []);
  const data = {
    formDefinition: state,
    setFormDefinition: (diff: Partial<FormDefinitionContextState>) => {
      const s = merge(initState, diff);
      setStateRef.current(s);
    },
    mergeFormDefinition: (diff: Partial<FormDefinitionContextState>) => {
      const s = merge(stateRef.current, diff);
      setStateRef.current(s);
    }
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.formDefinition = data;
  }
  return <FormDefinitionContext.Provider value={data}>
            {children}
        </FormDefinitionContext.Provider>;
}
export default FormDefinitionContextProvider;