import { version as uuidVersion } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import { v4 as uuidv4 } from 'uuid';
const reDigits = new RegExp("^\\d+$");
export const unflatten = (data, path = "", _path = true) => {
  const obj = {};
  let buff = Object.keys(data).filter(key => path ? key.startsWith(`${path}.`) || key === path : true).map(key => ({
    path: key,
    absolutePath: key,
    curPath: "",
    obj
  }));
  while (buff.length > 0) {
    const buffItem = buff.shift();
    if (!buffItem) {
      continue;
    }
    const pathItems = buffItem.path.split(".");
    const key = pathItems[0];
    if (pathItems.length > 1) {
      let biObj: any = null;
      if (uuidValidate(pathItems[1]) && uuidVersion(pathItems[1]) === 4) {
        if (buffItem.obj instanceof Array) {
          biObj = [];
          buffItem.obj.push(biObj);
        } else {
          biObj = [];
          buffItem.obj[key] = biObj;
        }
      } else if (buffItem.obj instanceof Array) {
        biObj = {
          _path: (buffItem.curPath ? buffItem.curPath + "." : "") + key
        };
        if (!_path) {
          delete biObj._path;
        }
        buffItem.obj.push(biObj);
      } else {
        biObj = {
          _path: (buffItem.curPath ? buffItem.curPath + "." : "") + key
        };
        if (!_path) {
          delete biObj._path;
        }
        buffItem.obj[key] = biObj;
      }
      buff.unshift(buffItem);
      buff = buff.map(item => {
        if (item.path.startsWith(`${key}.`)) {
          item.obj = biObj;
          item.curPath += (item.curPath ? "." : "") + item.path.slice(0, key.length);
          item.path = item.path.slice(key.length + 1);
        }
        return item;
      });
    } else {
      if (buffItem.obj instanceof Array) {
        buffItem.obj.push(data[buffItem.absolutePath]);
      } else {
        buffItem.obj[key] = data[buffItem.absolutePath];
      }
    }
  }
  if (path) {
    let ret = obj;
    const pathItemKeys = path.split(".");
    for (const pathItemKey of pathItemKeys) {
      if (ret instanceof Array && uuidValidate(pathItemKey) && uuidVersion(pathItemKey) === 4) {
        if (!ret) {
          return undefined;
        }
        ret = ret[0];
      } else {
        if (!ret) {
          return undefined;
        }
        ret = ret[pathItemKey];
      }
    }
    return (ret as any);
  }
  return (obj as any);
};
export default unflatten;