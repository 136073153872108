import FormContext from "@component/core/Context/Form/FormContext";
import { Combobox, Text as FluentText, Option } from "@fluentui/react-components";
import { Subtitle1 } from "@fluentui/react-components";
import { useId, Input, Label } from "@fluentui/react-components";
import { TextFieldRegular } from "@fluentui/react-icons";
import React from "react";
import { useContext, useEffect, useState } from "react";
const tag = "@component/view/Enum";
export function Enum({
  item,
  titleWidth,
  path = ""
}) {
  const {
    form,
    getByPath,
    updateByPath
  } = useContext(FormContext);
  const inputId = useId("input");
  const p = `${path ? path + "." : ""}${item.attrib}`;
  const [value, setValue] = useState(getByPath(p));
  const onChange = (ev, data) => {
    setValue(data.value);
    //updateByPath(p, data.value);
  };
  return <div style={{
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }}>
            {item.title && <FluentText style={{
      width: typeof titleWidth === "number" ? titleWidth : 250,
      flexShrink: 1
    }}>
                    {item.title}
                </FluentText>}
            <Combobox value={value} style={{
      flex: "1 1 auto"
    }}>
                {[value].map(option => <Option key={value}>
                        {value}
                    </Option>)}
            </Combobox>
        </div>;
}
export default Enum;