import { useContext, useEffect } from "react";
import NavigationContext from "@component/core/Context/Navigation/NavigationContext";
import FormContext from "@component/core/Context/Form/FormContext";
import { Text } from "@fluentui/react-components";
import View from "@common/View";
import { Item } from "@component/view/Form/Item";
import { Title1 } from "@fluentui/react-components";
import { Field, Textarea, tokens } from "@fluentui/react-components";
import FormDefinitionContext from "@component/core/Context/FormDefinition/FormDefinitionContext";
import flatten from "@lib/form/flatten";
const tag = "@component/view/Form";
export function Form({
  navItem
}) {
  const {
    form,
    setForm
  } = useContext(FormContext);
  const {
    formDefinition
  } = useContext(FormDefinitionContext);
  useEffect(() => {
    /*
    setForm(flatten({
        // Typ záznamu (formuláře)
        "typ": "PotvrzeniOPrevzeti",
    
        // Kdo a kdy uložil formulář
        "ulozilGUID": "9c32c6ed-d338-4046-8527-68dcfbaa9557",
        "ulozilNazev": "test",
        "ulozeno": "2024-05-06T13:30:49.9360977+02:00",
    
        // Příznak zda je formulář smazán
        "smazan": false,
    
        // Zda je formulář ukončen a není tak rozpracován
        "ukoncen": false,
    
        "identifikace": {
    
            // Poznámka: asi nejspíš nějaký GUID
            "stavba": "",
    
            "nazevStavby": "",
            "stavebniPovoleni": "",
            "smlouva": "",
    
            // Subjekty, lze přidávat i další subjekty
            "subjekt": {
                "objednatel": "",
                "zhotovitel": "",
                "stavbyvedouci": "",
                "budouciSpravceMajektu": {
                    "jmeno": "Budoucí",
                    "prijmeni": "Správce",
                    "email": "test@example.com"
                },
                "budouciSpravcePozemniKomunikace": "",
                "spravceStavby": "",
                "asistentSpravceStavby": "",
                "dalsiSubjekty": [
                    {
                        "Nazev": "Vlastní subjekt",
                        "Hodnota": "Jméno Příjmení"
                    }
                ]
            },
            
            "zahajeniPraci": "2024-05-06T13:30:49.9360977+02:00",
            "ukonceniPraci": "2024-05-06T13:30:49.9360977+02:00"
        },
        "technickyPopis": "",
        "stavebniObjekt": [
            {
                "stavebniObjekt": "objekt A",
                "typStavebnihoObjektu": "most",
                "podtyp": "Visutý most",
                "oblast": "Oblast",
                "cisloSilnice": "53",
                "evidencniCisloMostu": "A123",
                "provozniStaniceni": 123.59
            }
        ],
        "zmenyOprotiZadavaciDokumentaci": {
            "PozadovanyDodatecneStavebniPraceADodavky": true,
            "ZmenyOprotiDSP": "Změny oproti DSP",
            "ZmenyOprotiZDS": "Změny oproti ZDS",
            "ZmenyOprotiRDS": "Změny oproti RDS"
        },
        "dodatkyKeSmlouve": [
            {
                "cisloDodatku": "dodateA",
                "nazevDodatku": "název dodatku A",
                "obsahDodatku": "15.5.2024"
            },
            {
                "cisloDodatku": "DodateB",
                "nazevDodatku": "název dodatku B",
                "obsahDodatku": "4.4.2024"
            }
        ],
        "prejimaciRizeni": {
            "evidencnicislo": "F.8.36 V1.0",
            "zahajeno": "2024-05-08T00:00:00Z",
            "ukonceno": "2024-05-31T00:00:00Z"
        },
    
        // Poznámka: nemělo by toto být tolikrát kolik je stavebních objektů ?
        "osobyOpravneneStavbuSekciStavebniObjektPredatAPrevzit": {
            "zaZhotovitele": "za zhotovitele",
            "zaObjednatele": "Za objenatele",
            "zaSpravceStavby": "za Správce stavby",
            "zaAsistentaSpravceStavby": "za Asistent správce stavby",
            "zaBudoucihoSpravceMajetku": "za Budoucí správce majetku",
            "zaBudoucihoSpravceKomunikace": "za Budoucí správce komunikace"
        },
    
        "predavanaDokumentace": [
            {
                "cislo": 1,
                "text": "Dokumentace skutečného provedení Stavby (DSPS a všech jejích součástí)"
            },
            {
                "cislo": 2,
                "text": "Projekt sledování a údržby mostů / Návody na údržbu objektů / Provozní řády"
            },
            {
                "cislo": 3,
                "text": "Mostní list a 1, HMP včetně vložení do BMS"
            },
            {
                "cislo": 4,
                "text": "Souhrnná zpráva Zhotovitele o hodnocení jakosti stavebních prací (elektronická verze včetně dokladové části) včetně Dílčích zpráv Zhotovitele o hodnocení jakosti stavebních prací"
            },
            {
                "cislo": 5,
                "text": "Souhrnná zpráva Objednatele o hodnocení jakosti stavebních prací"
            },
            {
                "cislo": 6,
                "text": "Zpráva o hodnocení jakosti stavebních prací Správcem stavby (Asistentem Správce stavby) včetně Souhrnu provedených KZ Objednatele a nedokončených měření s vyjádřením Zhotovitele, Správce stavby (Asistenta správce stavby)"
            },
            {
                "cislo": 7,
                "text": "Geodetická dokumentace skutečného provedení Stavby /Sekce / Stavebního objektu (GDSP) včetně Hodnocení GDSP Stavby /Sekce / Stavebního objektu (ÚOZI)"
            },
            {
                "cislo": 8,
                "text": "Geometrický plán Stavby / Sekce / Stavebního objektu"
            },
            {
                "cislo": 9,
                "text": "Protokol o provedené zatěžovací zkoušce mostu (je-li předepsaná ZTKP, PDPS nebo RDS)"
            },
            {
                "cislo": 10,
                    "text": "Stavební deníky (originály)"
            },
            {
                "cislo": 11,
                    "text": "Zbývající doklady a dokumentace "
            }
        ],
    
        "rozdelovnik": [
            {
                "dokumentaciADokumentyUvedenePodCislem": "1 až 10",
                "forma": "Listinná – Originál",
                "pocet": 1,
                "prevzal": "Objednatel"
            },
            {
                "dokumentaciADokumentyUvedenePodCislem": "1 až 10",
                "forma": "Elektronická",
                "pocet": 1,
                "prevzal": "Objednatel"
            },
            {
                "dokumentaciADokumentyUvedenePodCislem": "1,2,3 a 6",
                "forma": "Listinná",
                "pocet": 1,
                "prevzal": "Správce majetku"
            },
            {
                "dokumentaciADokumentyUvedenePodCislem": "4,6,7 a 10",
                "forma": "Elektronická",
                "pocet": 1,
                "prevzal": "Správce majetku"
            },
            {
                "dokumentaciADokumentyUvedenePodCislem": "1,2,3 a 8",
                "forma": "Listinná",
                "pocet": 1,
                "prevzal": "Správce pozemní komunikace"
            },
            {
                "dokumentaciADokumentyUvedenePodCislem": "11",
                "forma": "Elektronická",
                "pocet": 1,
                "prevzal": "Správce stavby"
            },
            {
                "dokumentaciADokumentyUvedenePodCislem": "11",
                "forma": "Listinná / Elektronická",
                "pocet": 1,
                "prevzal": "Správce stavby"
            }
        ],
    
        // Poznámka: neměl by se evidovat u každé položky stavební objekt ?
        "zjisteneVady": [
            {
                "cislo": 1,
                "popisVady": "popis vady 1",
                "odstranitDo": "2024-05-07T00:00:00Z"
            },
            {
                "cislo": 2,
                "popisVady": "popis vady 2 ",
                "odstranitDo": "2024-05-16T00:00:00Z"
            }
        ],
    
        // Poznámka: neměl by se evidovat u každé položky stavební objekt ?
        "zjisteneNedodelky": [
            {
                "cislo": 1,
                "popisNedodelku": "nedodělek A",
                "provestDo": "2024-05-15T00:00:00Z"
                    
            },
            {
                "cislo": 2,
                "popisNedodelku": "nedodělek V",
                "provestDo": "2024-05-16T00:00:00Z"
            }
        ],
    
        "jinaUjednani": [
            {
                "text": "ujednani A"
            },
            {
                "text": "ujednani B"
            }
        ],
        "zaruky": {
            "zaruka": [
                {
                    "predmetZaruky": [
                        "objekt A",
                        "objekt B"
                    ],
                    "delkaZaruky": 20,
                    "pocatekZarucniDoby": "2024-05-06T00:00:00Z",
                    "konecZarucniDoby": "2024-05-10T00:00:00Z",
                    "omezeni": "omezení záruky"
                }
            ],
            "prodlouzeniZaruky": [
                {
                    "predmetZaruky": ["objekt C"],
                    "duvodProdlouzeni": "Důvod",
                    "prodlouzeniO": 25,
                    "novyKonecZarucniDoby": "2024-05-25T00:00:00Z"
                }
            ],
            "dilciZaruka": [
                {
                    "predmetZaruky": ["objekt D"],
                    "delkaZaruky": 10,
                    "pocatekZarucniDoby": "2024-05-06T00:00:00Z",
                    "konecZarucniDoby": "2024-05-10T00:00:00Z",
                    "omezeni": "omezení záruky"
                }
            ],
            "prodlouzeniDilciZaruky": [
                {
                    "predmetZaruky": ["objekt E"],
                    "duvodProdlouzeni": "Důvod",
                    "prodlouzeniO": 10,
                    "novyKonecZarucniDoby": "2024-05-25T00:00:00Z"
                }
            ],
            "zarucniVadyBudouUplatnovanyU": "..."
        },
    
        "prilohy": [
            {
                "cislo": 1,
                "nazev": "Zápis z provedené Hlavní prohlídky Stavby / Sekce / Stavebního objektu"
            }
        ]
    }));
    */

    setForm(flatten({
      "typ": "ProtokolOPredaniAPrevzeti",
      "evidencniCislo": "evidencniCislo.1",
      "evidencniCisloPotvrzeniOPrevzeti": "evidencniCisloPotvrzeniOPrevzeti.1",
      "priPrejimacimRizeniDokonceneStavbySekceStavebnihoObjektuZjisteneVady": [{
        "cisloVady": 1,
        "popisVady": "Popis vady",
        "vadaOdstranena": true
      }],
      "priPrejimacimRizeniDokonceneStavbySekceZjisteneNedodelky": [{
        "cisloNedodelku": 1,
        "popisNedodelku": "Popisek nedodělku",
        "nedodelekProveden": true
      }],
      "zarucniDobaNaOdstraneneVadyAProvedeneNedodelky": {
        "delkaZaruky": 24,
        "pocatekZaruky": "2024-05-06T13:30:49.9360977+02:00",
        "konecZaruky": "2024-05-06T13:30:49.9360977+02:00"
      },
      "prilohy": [{
        "cislo": 1,
        "nazev": "Zápis z provedené Hlavní prohlídky Stavby / Sekce / Stavebního objektu"
      }]
    }));
  }, []);
  return <View navItem={navItem} style={{
    maxWidth: 1024,
    border: "1px solid #BBBBBB",
    marginTop: 50,
    marginBottom: 50,
    backgroundColor: "#F7F7F7"
  }}>
            {formDefinition[form.typ] && <Title1 style={{
      alignSelf: "center",
      marginBottom: 20,
      color: tokens.colorPaletteBlueForeground2
    }}>
                    {formDefinition[form.typ].title}
                </Title1>}
            {((formDefinition[form.typ] || {}).sections || []).map((item, index) => {
      return <Item key={index} item={item} path="" />;
    })}
        </View>;
}
export default Form;