import { FormContext, initState } from "@component/core/Context/Form/FormContext";
import { FormContextState } from "@component/core/Context/Form/typeDefs";
import { useEffect, useRef, useState } from "react";
import merge from "@lib/merge";
import { unflatten } from "@lib/form/unflatten";
import flatten from "@lib/form/flatten";
const tag = "@component/core/Context/Form/FormContextProvider";
const setForm = (setState, diff: Partial<FormContextState>) => {
  const s = merge(initState, diff);
  setState(s);
};
const mergeForm = (setState, state, diff: Partial<FormContextState>) => {
  const s = merge(state, diff);
  setState(s);
};
const getByPath = (setState, state, path) => {
  if (typeof state[path] !== "undefined") {
    return state[path];
  }
  return unflatten(state, path, false);
};
const removeByPath = (setState, state, path) => {
  const form = {
    ...state
  };
  for (const key of Object.keys(form)) {
    if (key.startsWith(`${path}.`) || key === path) {
      delete form[key];
    }
  }
  setForm(setState, form);
};
const updateByPath = (setState, state, path, value) => {
  const v = flatten(value, path);
  mergeForm(setState, state, flatten(value, path));
};
const addByPath = (setState, state, path, value) => {
  let arr = unflatten(state, path, false);
  if (typeof arr === "undefined") {
    arr = [];
  }
  arr.push(value);
  mergeForm(setState, state, flatten(arr, path));
};
export function FormContextProvider({
  children
}) {
  const [state, setState] = useState(initState);
  const data = {
    form: state,
    setForm: (diff: Partial<FormContextState>) => setForm(setState, diff),
    mergeForm: (diff: Partial<FormContextState>) => mergeForm(setState, state, diff),
    getByPath: (path: "string") => getByPath(setState, state, path),
    removeByPath: (path: "string") => removeByPath(setState, state, path),
    addByPath: (path: string, value: any) => addByPath(setState, state, path, value),
    updateByPath: (path: string, value: any) => updateByPath(setState, state, path, value)
  };
  if (process.env.NODE_ENV === 'development') {
    window["web"].context.form = data;
  }
  return <FormContext.Provider value={data}>
            {children}
        </FormContext.Provider>;
}
export default FormContextProvider;